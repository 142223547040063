import { SET_BREADCRUMB } from "@/store/common/breadcrumbs.module";

export default {
  components: {},

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "NL" },
      { title: "Navigation Menu NL" }
    ]);
  },
  data: () => ({
    navigation: [
      // {
      //   header: "Dashboards",
      //   links: [
      //     {
      //       name: "Criticals",
      //       to: "dashboards/criticals",
      //       icon: "mdi-bell"
      //     }
      //   ],
      //   flexmd: 4,
      //   flexsm: 6
      // },
      // {
      //   header: "Monitoring",
      //   links: [
      //     {
      //       name: "Muting rules",
      //       to: "monitoring/muting-rules",
      //       icon: "mdi-table-cancel"
      //     }
      //   ],
      //   flexmd: 4,
      //   flexsm: 6
      // }
    ]
  })
};
